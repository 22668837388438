<template>
  <div>
    <slot />

    <div
      v-if="direction === 'vertical'"
      class="border-bottom q-pa-sm text-subtitle1 text-capitalize row items-center q-item q-item-type q-item--clickable q-link cursor-pointer q-focusable q-hoverable"
      @click="toggleOpen"
    >
      <div class="q-focus-helper"></div>

      <div>
        {{ title }}
      </div>

      <q-space />

      <div v-if="overflow === 'hidden'" class="q-px-sm">
        <q-icon
          :name="isOpen ? 'expand_less' : 'expand_more'"
          size="24px"
          style="color: #757575;"
        />
      </div>
    </div>

    <div :class="styleClasses">
      <div
        :class="wrapperStyleClasses"
        :style="wrapperStyles"
        style="max-height: auto !important;"
      >
        <div class="q-pa-sm" :class="cardStyleClasses">
          <q-card
            class="clickable row items-center justify-center"
            style="height: 64px;"
            @click="handleAddPlace"
          >
            <q-card-section class="text-center q-py-none">
              <q-icon name="add" size="25px" />
            </q-card-section>
          </q-card>
        </div>

        <div
          :class="wrapperStyleClasses"
          :style="wrapperStyles"
        >
          <div
            v-for="item in activePlaces"
            :key="item.id"
            class="q-pa-sm"
            :class="cardStyleClasses"
            :style="direction === 'vertical' ? 'min-width: 200px;' : ''"
          >
            <warehouse-place-card
              :level="level"
              :offline="!warehouse || !warehouse.id"
              :place="item"
              :selected="place && place.id === item.id"
              @edit="handleEditPlace"
              @select="handleSelect"
              @print="handlePrint"
            />
          </div>
        </div>
      </div>

      <div
        v-if="place"
        class="col"
      >
        <warehouse-places
          :level="level - 1"
          :direction="direction"
          :key="place.id"
          :parent="place"
          :warehouse="warehouse"
          :type="requiredType"
          :title="nextTitle"
          :is-new-place="!hasPlaceInOrPlaces"
          @places="handleEmitPlaces"
          @change="handleEmitChanges"
        />
      </div>
    </div>

    <warehouse-place-modal ref="placeModal" />
  </div>
</template>

<script>
// Utils
import _ from 'lodash'

// Components
import WarehousePlaceCard from './WarehousePlaceCard.vue'
import WarehousePlaceModal from './WarehousePlaceModal.vue'

export default {
  name: 'WarehousePlaces',
  emits: ['change', 'places'],
  components: {
    WarehousePlaceCard,
    WarehousePlaceModal
  },
  props: {
    overflow: {
      type: String,
      default () {
        return 'auto'
      }
    },
    level: {
      type: Number,
      default () {
        return 7
      }
    },
    direction: {
      type: String,
      default () {
        return 'vertical'
      }
    },
    isNewPlace: {
      type: Boolean,
      default () {
        return false
      }
    },
    isManual: {
      type: Boolean,
      default () {
        return false
      }
    },
    parent: {
      type: Object,
      default () {
        return null
      }
    },
    title: {
      type: String,
      default () {
        return ''
      }
    },
    type: {
      type: String,
      default () {
        return null
      }
    },
    warehouse: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      isOpen: false,
      placesWithChildren: ['section', 'room'],
      place: null,
      places: [],
      originalPlaces: [],
      types: {
        room: 'Orderadmin\\Storage\\Entity\\Place\\Room',
        row: 'Orderadmin\\Storage\\Entity\\Place\\Row',
        section: 'Orderadmin\\Storage\\Entity\\Place\\Section',
        static: 'Orderadmin\\Storage\\Entity\\Place',
        dynamic: 'Orderadmin\\Storage\\Entity\\Place\\Dynamic',
        employee: 'Orderadmin\\Storage\\Entity\\Place\\Employee',
        sorting: 'Orderadmin\\Storage\\Entity\\Place\\Sorting',
        distribution: 'Orderadmin\\Storage\\Entity\\Place\\Distribution',
        distribution_rejected: 'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected',
        assembly: 'Orderadmin\\Storage\\Entity\\Place\\Assembly',
        universal: 'Orderadmin\\Storage\\Entity\\Place\\Universal',
        pallet: 'Orderadmin\\Storage\\Entity\\Place\\Pallet',
        defected: 'Orderadmin\\Storage\\Entity\\Place\\Defected'
      }
    }
  },
  computed: {
    wrapperStyles () {
      if (this.direction === 'vertical') {
        if (this.overflow === 'hidden' && !this.isOpen) {
          return 'overflow: hidden; max-height: 82px;'
        }

        return 'max-height: 60vh; overflow: auto;'
      }

      return 'width: min-content; min-width: 200px; max-height: 60vh; overflow: auto;'
    },
    wrapperStyleClasses () {
      if (this.direction === 'vertical') {
        return 'col row border-bottom styled-scrollbar'
      }

      return 'border-right styled-scrollbar'
    },
    cardStyleClasses () {
      return this.direction === 'horizontal'
        ? 'col-12'
        : ''
    },
    styleClasses () {
      return this.direction === 'horizontal'
        ? 'row border-bottom'
        : 'row'
    },
    nextTitle () {
      const base = `${this.place.placeAlias || this.$t('No name')} (${this.place.id})`

      if (!this.parent) {
        return base
      }

      return `${this.parent.placeAlias || this.$t('No name')} (${this.parent.id}) -> ${base}`
    },
    hasPlaceInOrPlaces () {
      if (!this.place) {
        return false
      }

      return !!this.originalPlaces.find(x => x.id === this.place.id)
    },
    activePlaces () {
      return this.places.filter(({ state }) => !state || state === 'active' || state === 'normal')
    },
    requiredType () {
      if (this.place && this.place.type === 'room') {
        return 'section'
      }

      return null
    }
  },
  watch: {
    parent () {
      return this.reset()
    }
  },
  mounted () {
    if (!this.isManual) {
      return this.reset()
    }
  },
  methods: {
    toggleOpen () {
      this.isOpen = !this.isOpen
    },
    emitChanges (data) {
      this.$emit('change', data)
    },
    handleEmitChanges (data) {
      this.emitChanges(data)
    },
    _getOriginalPlaces (places) {
      return places.reduce((acc, place) => {
        acc.push({ ...place, children: undefined, originalChildren: undefined })
        const children = this._getOriginalPlaces(place.originalChildren || []).map(x => ({ ...x, parent: place.id }))
        return [...acc, ...children]
      }, [])
    },
    getPlacesFromNewPlace (place) {
      return {
        items: place.children || []
      }
    },
    reset () {
      this.place = null
      this.places = []
      this.originalPlaces = []

      return Promise.resolve(this.isNewPlace)
        .then(isNewPlace => {
          return isNewPlace
            ? this.getPlacesFromNewPlace(this.parent)
            : this.loadPlaces(this.parent)
        })
        .then(data => {
          this.places = data.items
          this.originalPlaces = _.cloneDeep(data.items)
          this.$emit('places', { places: this.places, originalPlaces: this.originalPlaces })
          return data
        })
    },
    getPlaces () {
      return this.places
    },
    getOriginalPlaces () {
      return this._getOriginalPlaces(this.originalPlaces)
    },
    getNewPlace (type) {
      const place = {
        type,
        placeAlias: ``
      }

      return Promise.resolve(place)
    },
    getType () {
      if (this.type) {
        return this.type
      }

      if (this.place && this.placesWithChildren.includes(this.place.type)) {
        return this.place.type
      }

      return null
    },
    getPlace () {
      const type = this.getType()
      const unlockedFields = ['placeAlias']

      if (!type) {
        unlockedFields.push('type')
      }

      return this.getNewPlace(type)
        .then(place => {
          return this.$refs.placeModal.show(place, unlockedFields)
        })
    },
    handleEmitPlaces ({ places, originalPlaces }) {
      if (!this.place) {
        return
      }

      this.place.children = places
      this.place.originalChildren = originalPlaces

      this.places = this.replacePlace(this.place, this.places)
      this.originalPlaces = this.originalPlaces.map(x => {
        if (x.id === this.place.id) {
          x.originalChildren = originalPlaces
        }

        return x
      })

      this.$emit('places', { places: this.places, originalPlaces: this.originalPlaces })
    },
    handleAddPlace () {
      return this.getPlace()
        .then(place => {
          if (!place) {
            return
          }

          const newPlaces = []
          const names = place.count || ['']

          if (names.length > 1) {
            for (let i = 0; i < names.length; i++) {
              newPlaces.push({ ...place, id: Math.floor(Math.random() * (9999999999) + 1), count: undefined, placeAlias: `${place.placeAlias}${names[i]}` })
            }
          } else {
            newPlaces.push({ ...place, id: Math.floor(Math.random() * (9999999999) + 1), count: undefined })
          }

          this.places = [...newPlaces, ...this.places]
          this.$emit('places', { places: this.places, originalPlaces: this.originalPlaces })
          this.emitChanges(this.places)
        })
    },
    loadPlaces (place) {
      if (place && !this.placesWithChildren.includes(place.type)) {
        return Promise.resolve({ items: [] })
      }

      if (place && place.children && place.children.length > 0) {
        return Promise.resolve({ items: place.children })
      }

      if (!this.warehouse.id) {
        return Promise.resolve({ items: [] })
      }

      const query = {
        per_page: 250,
        page: 1,
        filter: [
          { type: 'eq', field: 'warehouse', value: this.warehouse.id },
          { type: 'in', field: 'state', values: ['active', 'normal'] }
        ],
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      }

      if (place) {
        query.filter.push({ type: 'eq', field: 'parent', value: place.id })
      }

      if (this.type) {
        query.filter.push({ type: 'eq', field: 'type', value: this.type })
      }

      return this.$service.storagePlace.getAll(query)
    },
    replacePlace (place, places) {
      return places.map(x => {
        return x.id === place.id
          ? place
          : x
      })
    },
    handleEditPlace (place) {
      const unlockedFields = ['placeAlias']

      if (!place.id && !this.placesWithChildren.includes(place.type)) {
        unlockedFields.push('type')
      }

      const options = {
        showId: !!this.originalPlaces.find(x => x.id === place.id)
      }

      return this.$refs.placeModal.show(place, unlockedFields, options)
        .then(place => {
          if (!place) {
            return
          }

          if (this.place && this.place.id === place.id) {
            this.place = place.state === 'inactive'
              ? null
              : place
          }

          this.places = this.replacePlace(place, this.places)
          this.$emit('places', { places: this.places, originalPlaces: this.originalPlaces })
          this.emitChanges(this.places)
          return place
        })
    },
    handleSelect (place) {
      this.place = place
    },
    handlePrint (event) {
      const query = {
        filter: []
      }

      if (!event.children) {
        query.filter.push({ type: 'eq', field: 'id', value: event.place?.id })
      } else {
        query.filter.push({ type: 'eq', field: 'root', value: event.place?._embedded?.root?.id })
        query.filter.push({ type: 'gte', field: 'lft', value: event.place?.lft })
        query.filter.push({ type: 'lte', field: 'lft', value: event.place?.rgt })
      }

      this.$service.storagePlace.printAll(query)
          .then(({ text }) => {
            this.$service.printer.print(text, 'LABEL', undefined, true)
          })
      console.log(event)
    },
  }
}
</script>
