<template>
  <form-builder ref="formBuilder" :schema="schema" />
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'WarehouseForm',
  emits: ['change'],
  props: {
    hasParent: {
      type: Boolean,
      default () {
        return false
      }
    },
    hasType: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      states: ['active', 'inactive'],
      allTypes: ['client', 'fulfillment', 'virtual'],
      fieldsWithError: []
    }
  },
  computed: {
    ...mapGetters([
      'warehouse',
      'cleanWarehouse',
      'currentUser',
      'isSupervisior',
      'isAdministrator',
      'isClient'
    ]),
    types () {
      if (this.isSupervisior) {
        return this.allTypes.filter(val => val !== 'client')
      }

      return this.allTypes
    },
    schema () {
      let styleClasses = ''

      if (!this.warehouse || this.warehouse.id) {
        styleClasses = 'd-none'
      } else {
        styleClasses = this.warehouse.type === 'virtual'
          ? 'col-12 col-md-6 q-pa-sm'
          : 'col-12 q-pa-sm'
      }

      return {
        isLoading: !this.warehouse,
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.warehouse && this.warehouse.name,
                label: this.$t('Name'),
                field: 'name',
                error: this.fieldsWithError.includes('name') && 'Please fill name!',
                wrapperStyleClasses: 'col-12 col-md-10 q-pa-sm',
                required: true,
                onChange: name => {
                  this.updateWarehouse({ name })

                  if (name) {
                    this.removeField('name')
                  }

                  this.$emit('change', this.warehouse)
                }
              },
              {
                type: 'select',
                label: this.$t('Status'),
                value: this.warehouse && this.warehouse.state,
                customLabel: (row) => {
                  if (!row) {
                    return ''
                  }

                  return this.$t(row)
                },
                wrapperStyleClasses: 'col-12 col-md-2 q-pa-sm',
                options: this.states,
                field: 'state',
                error: this.fieldsWithError.includes('state') && 'Please fill status!',
                disabled: this.warehouse && !this.warehouse.id || this.isClient,
                required: true,
                onChange: (state) => {
                  this.updateWarehouse({ state })

                  if (state) {
                    this.removeField('state')
                  }

                  this.$emit('change', this.warehouse)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Fulfillment facility'),
                value: this.warehouse && this.warehouse._embedded.parent,
                wrapperStyleClasses: this.warehouse && this.warehouse.type === 'virtual'
                  ? 'col-12 col-md-6 q-pa-sm'
                  : 'd-none',
                disabled: this.warehouse && (!this.warehouse.id && this.hasParent) || !!(this.warehouse && this.warehouse.id && this.cleanWarehouse._embedded.parent),
                required: this.type === 'virtual',
                field: 'parent',
                error: this.fieldsWithError.includes('parent') && 'Please fill fulfillment facility!',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    fields: ['extended'],
                    filter: [
                      { type: 'eq', field: 'type', value: 'fulfillment' },
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: (parent) => {
                  this.updateBaseData(parent)

                  if (parent) {
                    this.removeField('parent')
                  }

                  this.$emit('change', this.warehouse)
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                value: this.warehouse && this.warehouse.type,
                customLabel: (row) => {
                  if (!row) {
                    return ''
                  }

                  const text = row[0].toUpperCase() + row.slice(1).toLowerCase()
                  return this.$t(text)
                },
                wrapperStyleClasses: styleClasses,
                disabled: this.hasType,
                required: true,
                field: 'type',
                error: this.fieldsWithError.includes('type') && 'Please fill type!',
                options: this.types,
                onChange: (type) => {
                  this.updateWarehouse({ type })
                  this.updateWarehouseEmbedded({ owner: null })
                  this.resetOwnerField()

                  if (type) {
                    this.removeField('type')
                  }

                  this.$emit('change', this.warehouse)
                }
              },
              {
                ref: 'owner',
                if: this.isAdministrator || this.isSupervisior,
                type: 'multiselect',
                label: this.$t('Owner'),
                value: this.warehouse && this.warehouse._embedded.owner,
                wrapperStyleClasses: 'col-12 q-pa-sm',
                disabled: !!(this.warehouse && this.warehouse.id),
                required: true,
                field: 'owner',
                error: this.fieldsWithError.includes('owner') && 'Please fill owner!',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  if (this.warehouse.type === 'fulfillment') {
                    query.filter = [
                      {
                        type: 'innerjoin',
                        field: 'roles',
                        parentAlias: 'u',
                        alias: 'r'
                      },
                      {
                        field: 'id',
                        alias: 'r',
                        type: 'eq',
                        value: 29
                      }
                    ]
                  } else {
                    query.filter = [
                      {
                        type: 'innerjoin',
                        field: 'roles',
                        parentAlias: 'u',
                        alias: 'r'
                      },
                      {
                        field: 'id',
                        alias: 'r',
                        type: 'eq',
                        value: 8
                      }
                    ]
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: (owner) => {
                  this.updateWarehouseEmbedded({ owner })

                  if (owner) {
                    this.removeField('owner')
                  }

                  this.$emit('change', this.warehouse)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Country'),
                value: this.warehouse && this.warehouse._embedded.country,
                wrapperStyleClasses: 'col-12 col-md-3 q-pa-sm',
                disabled: !!(this.warehouse && this.warehouse.id && this.cleanWarehouse._embedded.country),
                required: true,
                field: 'country',
                error: this.fieldsWithError.includes('country') && 'Please fill country!',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.name
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.country.getAll(query)
                },
                onChange: (country) => {
                  this.updateWarehouseEmbedded({ country })

                  if (country) {
                    this.removeField('country')
                  }

                  this.$emit('change', this.warehouse)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Currency'),
                value: this.warehouse && this.warehouse._embedded.currency,
                wrapperStyleClasses: 'col-12 col-md-3 q-pa-sm',
                disabled: !!(this.warehouse && this.warehouse.id && this.cleanWarehouse._embedded.currency),
                required: true,
                field: 'currency',
                error: this.fieldsWithError.includes('currency') && 'Please fill currency!',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.title || row.name
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.currency.getAll(query)
                },
                onChange: (currency) => {
                  this.updateWarehouseEmbedded({ currency })

                  if (currency) {
                    this.removeField('currency')
                  }

                  this.$emit('change', this.warehouse)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Length Units'),
                value: this.warehouse && this.warehouse._embedded.length,
                wrapperStyleClasses: 'col-12 col-md-3 q-pa-sm',
                disabled: !!(this.warehouse && this.warehouse.id && this.cleanWarehouse._embedded.length),
                required: true,
                field: 'length',
                error: this.fieldsWithError.includes('length') && 'Please fill length units!',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.title
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.length.getAll(query)
                },
                onChange: (length) => {
                  this.updateWarehouseEmbedded({ length })

                  if (length) {
                    this.removeField('length')
                  }

                  this.$emit('change', this.warehouse)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Weight Units'),
                value: this.warehouse && this.warehouse._embedded.weight,
                wrapperStyleClasses: 'col-12 col-md-3 q-pa-sm',
                disabled: !!(this.warehouse && this.warehouse.id && this.cleanWarehouse._embedded.weight),
                required: true,
                field: 'weight',
                error: this.fieldsWithError.includes('weight') && 'Please fill weight units!',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.title
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.weight.getAll(query)
                },
                onChange: (weight) => {
                  this.updateWarehouseEmbedded({ weight })

                  if (weight) {
                    this.removeField('weight')
                  }

                  this.$emit('change', this.warehouse)
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateWarehouse',
      'updateWarehouseEmbedded'
    ]),
    resetOwnerField () {
      if (this.$refs.formBuilder.$refs.form && this.$refs.formBuilder.$refs.form[0] && this.$refs.formBuilder.$refs.form[0].$refs.owner) {
        this.$refs.formBuilder.$refs.form[0].$refs.owner[0].$refs.owner.reset()
      }
    },
    removeField (field) {
      this.fieldsWithError = this.fieldsWithError.filter(x => x !== field)
    },
    updateBaseData (parent) {
      if (!parent._embedded) {
        parent._embedded = {}
      }

      this.updateWarehouseEmbedded({
        parent,
        country: parent._embedded.country || this.getValue(parent.country),
        currency: parent._embedded.currency || this.getValue(parent.currency),
        length: parent._embedded.length || this.getValue(parent.length),
        weight: parent._embedded.weight || this.getValue(parent.weight)
      })
    },
    setFieldsWithErrors (fields) {
      this.fieldsWithError = fields
    },
    getValue (val) {
      return typeof val === 'string'
        ? JSON.parse(val)
        : val
    },
    getFieldsWithoutValue () {
      return this.schema.groups[0].fields.filter(x => {
        return !!x.required && !x.value
      })
    }
  }
}
</script>
