<template>
  <q-dialog v-model="isOpen" class="modal-exl">
    <q-card>
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Locations') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Print barcodes')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="printBarcodes"
        />
      </q-card-actions>

      <q-card-section>
        <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          virtual-scroll
          binary-state-sort
          flat
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <storage-place-row :data="props" />
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import StoragePlaceRow from '../tables/StoragePlaceRow.vue'

export default {
  name: 'StoragePlaceResultModal',
  emits: ['submit'],
  components: {
    StoragePlaceRow
  },
  data () {
    return {
      isOpen: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Location address and type'),
          name: 'location',
          align: 'left'
        },
        {
          label: this.$t('Code') + ' (S/P/\'ID\'), ' + this.$t('Parent code'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Warehouse'),
          name: 'warehouse',
          align: 'left'
        },
        {
          label: this.$t('Pin'),
          name: 'pin',
          align: 'center'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Label'),
          name: 'label',
          align: 'center'
        }
      ],
      items: []
    }
  },
  methods: {
    printBarcodes () {
      const query = {
        filter: [
          { type: 'in', field: 'id', values: this.items.map(x => x.id) }
        ]
      }

      this.$service.storagePlace.printAll(query)
        .then(({ text }) => {
          this.$service.printer.print(text, 'LABEL', undefined, true)
        })
    },
    resolve () {},
    reject () {},
    close () {
      this.isOpen = false
      this.resolve(true)
    },
    show (items) {
      this.items = items
      this.pagination = {
        descending: true,
        page: 1,
        rowsPerPage: this.items.length,
        rowsNumber: this.items.length
      }

      this.isOpen = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    }
  }
}
</script>
