<template>
  <q-dialog v-model="isOpen">
    <q-card v-if="isOpen" class="modal-md">
      <q-card-section class="row">
        <div class="text-h6 text-center text-capitalize">
          {{ `${title} ${id ? `(${$t("ID")}: ${id})` : ""}` }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="hide"
        />
      </q-card-section>

      <q-carousel
        :model-value="slide"
        transition-prev="scale"
        transition-next="scale"
        animated
        height="auto"
      >
        <q-carousel-slide name="options" class="row justify-center">
          <div
            class="col-4 q-px-sm"
            style="min-height: 200px; min-width: 300px"
          >
            <q-card
              class="fit card--clickable"
              @click="handleForm(unlockedFields)"
            >
              <q-card-section
                class="row fit text-center items-center justify-center"
              >
                <div>
                  <q-icon name="person_add_alt_1" size="2rem" />

                  <h5 class="q-my-none">
                    {{ $t("Create") + " " + title }}
                  </h5>
                </div>
              </q-card-section>
            </q-card>
          </div>

          <div
            class="col-4 q-px-sm"
            style="min-height: 200px; min-width: 300px"
          >
            <q-card
              class="fit card--clickable"
              @click="handleForm([...unlockedFields, 'to', 'from'])"
            >
              <q-card-section
                class="row fit text-center items-center justify-center"
              >
                <div>
                  <q-icon name="group_add" size="2rem" />

                  <h5 class="q-my-none">
                    {{ $t("Create bulk") + " " + subtitle }}
                  </h5>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </q-carousel-slide>

        <q-carousel-slide name="form" class="q-pa-none">
          <q-card-section class="q-py-none">
            <div
              v-if="unlockedFields.includes('from')"
              class="text-center text-grey-7 text-subtitle1 q-mb-sm"
            >
              {{
                this.$t(
                  "Max 5 characters for a name of a single location including a Prefix. Please set the range with numbers or letters."
                )
              }}
            </div>

            <form-builder :schema="schema" />
          </q-card-section>

          <div v-if="from !== null && to !== null" class="text-center q-pt-xl">
            <div class="text-h6 q-mb-sm">
              {{ `${count.length} ${subtitle} ${$t("in the range of")}` }}
            </div>

            <div class="text-h4 text-weight-bold">
              {{
                `${model.placeAlias || ""}${from} - ${
                  model.placeAlias || ""
                }${to}`
              }}
            </div>
          </div>

          <q-card-section
            v-if="model.type === 'room' && !unlockedFields.includes('from')"
            class="row q-pt-none"
          >
            <div class="col-12 col-md-6 q-pa-xs">
              <div class="q-pa-xs border rounded q-mb-xs">
                <div class="text-subtitle1 text-center text-weight-bold">
                  {{ $t("Coordinates finder") }}
                </div>

                <base-address
                  :address="address"
                  not-required
                  full-width
                  @change="handleAddressChange"
                />

                <form-builder :schema="mapSchema" />
              </div>

              <form-builder :schema="coordsSchema" />
            </div>

            <div class="col-12 col-md-6 q-pa-sm" style="min-height: 300px">
              <l-map
                ref="addressMap"
                :zoom="zoom"
                :center="center"
                @dblclick="onMapClick"
              >
                <l-tile-layer :url="url" :attribution="attribution" />

                <l-marker v-if="marker" :lat-lng="marker" />
              </l-map>
            </div>
          </q-card-section>

          <q-card-section class="text-center q-mt-md">
            <q-btn
              color="white"
              text-color="dark"
              class="q-mr-sm"
              :label="$t('Discard')"
              @click="hide"
            />
            <q-btn
              color="light-blue-9"
              text-color="white"
              class="q-mr-sm"
              :label="$t('Save')"
              @click="save"
            />

            <q-btn
              v-if="model && model.id"
              color="danger"
              text-color="white"
              :label="$t('Delete')"
              @click="handleDelete"
            />
          </q-card-section>
        </q-carousel-slide>
      </q-carousel>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'
import BaseAddress from '../clients/BaseAddress.vue'

export default {
  name: 'WarehousePlaceModal',
  components: {
    'l-map': LMap,
    'l-tile-layer': LTileLayer,
    'l-marker': LMarker,
    BaseAddress
  },
  data () {
    return {
      options: {},
      zoom: 13,
      center: {
        lat: 43.204666,
        lng: 27.910543
      },
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: {
        lat: 43.204666,
        lng: 27.910543
      },
      address: {
        postcode: null,
        _embedded: {
          locality: null
        }
      },
      slide: 'options',
      from: null,
      to: null,
      isOpen: false,
      model: {},
      unlockedFields: [],
      typeNames: {
        employee: 'basket'
      },
      types: [
        { id: 'section', name: this.$t('Section') },
        { id: 'static', name: this.$t('Static') },
        { id: 'dynamic', name: this.$t('Dynamic') },
        { id: 'sorting', name: this.$t('Sorting') },
        { id: 'distribution', name: this.$t('Distribution') },
        { id: 'distribution_rejected', name: this.$t('Distribution_rejected') },
        { id: 'assembly', name: this.$t('Assembly') },
        { id: 'universal', name: this.$t('Universal') },
        { id: 'pallet', name: this.$t('Pallet') },
        { id: 'defected', name: this.$t('Defected') }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    mapSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'address',
                label: this.$t('Address search'),
                field: 'notFormal',
                value: this.address.notFormal,
                wrapperStyleClasses: 'q-pa-xs',
                googleApiKey: this.appOptions.googleApiKey,
                onInput: (notFormal) => {
                  this.address.notFormal = notFormal
                },
                onPlaceChanged: (value, place) => {
                  let localAddress = {
                    postcode: place.postal_code,
                    notFormal: this.address.notFormal,
                    _embedded: {
                      locality: this.address._embedded.locality
                    }
                  }

                  if (value.x && value.y) {
                    this.setCoords(value)
                  }

                  if (!place.postal_code) {
                    return
                  }

                  localAddress.postcode = place.postal_code

                  return this.loadLocalitiesByPostcode(localAddress.postcode)
                    .then(locality => {
                      if (!locality) {
                        return
                      }

                      if (!value.x && !value.y && locality.geo) {
                        this.setCoords(this.$service.storagePlace._convertGeo(locality.geo))
                      }

                      localAddress._embedded.locality = locality
                      this.address = localAddress
                    })
                }
              }
            ]
          }
        ]
      }
    },
    coordsSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Longitude'),
                value: this.marker.lat,
                wrapperStyleClasses: 'col-6 col-md-5 q-pa-xs',
                onChange: x => {
                  this.marker.lat = x
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Latitude'),
                value: this.marker.lng,
                wrapperStyleClasses: 'col-6 col-md-5 q-pa-xs',
                onChange: y => {
                  this.marker.lng = y
                }
              }
            ]
          }
        ]
      }
    },
    id () {
      return this.options.showId
        ? this.model.id
        : ''
    },
    title () {
      if (!this.model.type) {
        return this.$t('location')
      }

      return this.$t(this.typeNames[this.model.type] || this.model.type)
    },
    subtitle () {
      if (!this.model.type) {
        return this.$t('locations')
      }

      return this.$t((this.typeNames[this.model.type] || this.model.type) + 's')
    },
    schema () {
      let length = 5 - `${this.to || ''}`.length

      if (length < 0) {
        length = 0
      }

      const fields = [
        {
          type: 'input',
          label: this.$t('Range from'),
          value: this.from,
          field: 'from',
          max: 5,
          onInput: value => {
            const n = Number(value)

            if (!isNaN(n) && n < 0) {
              this.addErrorNotification('\'Range from\' must be greater than 0!')
              return
            }

            this.from = (value || '').toUpperCase()
          }
        },
        {
          type: 'input',
          label: this.$t('Range to'),
          value: this.to,
          field: 'to',
          max: 5,
          onInput: value => {
            const n = Number(value)

            if (!isNaN(n) && n < 0) {
              this.addErrorNotification('\'Range from\' must be greater than 0!')
              return
            }

            this.to = (value || '').toUpperCase()
          }
        },
        {
          type: 'input',
          inputType: 'text',
          label: this.unlockedFields.includes('from')
            ? this.$t('Prefix (optional)')
            : this.$t('Name'),
          value: this.model.placeAlias,
          field: 'placeAlias',
          hint: !this.unlockedFields.includes('from')
            ? `${this.$t('Max')} ${length} ${this.$t('characters. Please use letters and numbers only. For example, 1, ABC, AB1, XZ, 37, etc.')}`
            : undefined,
          max: length,
          required: !this.unlockedFields.includes('to'),
          onInput: value => {
            this.model.placeAlias = value
          }
        },
        {
          type: 'select',
          label: this.$t('Type'),
          field: 'type',
          options: this.types,
          value: this.model.type,
          required: true,
          customLabel (row) {
            return row && typeof row === 'object'
              ? row.name
              : row
          },
          onChange: (type) => {
            this.model.type = type.id
          }
        }
      ]

      const activeFields = fields.filter(({ field }) => this.unlockedFields.includes(field))

      activeFields.forEach(field => {
        field.wrapperStyleClasses = `col-12 col-md-${Math.floor((12 / activeFields.length))} q-pa-xs`
      })

      return {
        groups: [
          {
            styleClasses: 'row',
            fields: activeFields
          }
        ]
      }
    },
    count () {
      if (this.from === null || this.to === null) {
        return []
      }

      return this.$utils.generateCodes(this.from, this.to)
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    loadLocalitiesByPostcode (value) {
      const query = {
        page: 1,
        per_page: 25,
        filter: [
          { field: 'extId', type: 'eq', value }
        ]
      }

      return this.$service.postcode.getAll(query)
        .then(({ items }) => {
          if (items.length === 0) {
            this.addWarningNotification('No localities found!')
            return
          }

          const localities = items.reduce((acc, item) => {
            const locality = item._embedded.locality

            if (!acc.find(x => x.id === locality.id)) {
              acc.push(locality)
            }

            return acc
          }, [])

          if (localities.length === 1) {
            return localities[0]
          }
        })
    },
    setCoords({ x, y }) {
      this.center = {
        lat: x,
        lng: y
      }

      this.marker = this.center
    },
    handleAddressChange (address) {
      this.address = address

      if (this.address._embedded.locality && this.address._embedded.locality.geo) {
        this.setCoords(this.$service.storagePlace._convertGeo(this.address._embedded.locality.geo))
      }
    },
    getUserPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          this.center = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude
          }
        })
      }
    },
    onMapClick(value) {
      if (value.latlng) {
        this.marker = value.latlng
      }
    },
    handleForm (fields) {
      this.unlockedFields = fields
      this.slide = 'form'
    },
    resolve () {},
    reject () {},
    show (place, unlockedFields = [], options = {}) {
      this.model = { ...place }
      this.options = options
      this.from = null
      this.to = null

      if (place.id) {
        this.slide = 'form'
      }

      if (!this.model.dimensions) {
        this.model.dimensions = {
          x: 0,
          y: 0,
          z: 0
        }
      }

      this.unlockedFields = unlockedFields
      this.isOpen = true

      this.address = {
        postcode: null,
        _embedded: {
          locality: null
        }
      }

      if (this.model.geoArray && this.model.geoArray.x && this.model.geoArray.y) {
        this.setCoords(this.model.geoArray)
      } else {
        this.getUserPosition()
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      }).catch(err => {
        console.warn(err.message)
      })
    },
    handleDelete () {
      this.model.state = 'inactive'
      return this.save()
    },
    hide () {
      this.slide = 'options'
      this.isOpen = false
      return this.reject(new Error('Form is closed!'))
    },
    save () {
      const place = { ...this.model }

      if (place.type === 'room') {
        place.geoArray = {
          x: this.marker.lat,
          y: this.marker.lng
        }
      }

      let length = 5 - `${this.to || ''}`.length

      if (place.placeAlias.length > length) {
        this.addErrorNotification(this.$t('Please use maximum') + ` ${length} ` + this.$t('characters for location name.'))
        return
      }

      if (this.from !== null && this.to === null) {
        this.addErrorNotification('Please set \'Range to\'!')
        return
      }

      if (this.from === null && this.to !== null) {
        this.addErrorNotification('Please set \'Range from\'!')
        return
      }

      if (this.from !== null && this.to !== null) {
        const from = this.$utils.convertToNumber(this.from)
        const to = this.$utils.convertToNumber(this.to)

        if (Number(from.value) > Number(to.value)) {
          this.addErrorNotification('Range from can\'t be bigger that range to!')
          return
        }

        place.count = this.$utils.generateCodes(this.from, this.to)
      }

      if (!this.model.type) {
        this.addErrorNotification('Type of location is required!')
        return
      }

      if (!this.model.placeAlias && !place.count) {
        this.addErrorNotification('Location name is required!')
        return
      }

      this.isOpen = false
      this.slide = 'options'
      return this.resolve(place)
    }
  }
}
</script>
