<template>
  <q-card
      class="place-card q-py-sm"
      :class="className"
      style="min-height: 40px;"
  >
    <q-card-section class="text-dark q-px-sm q-py-none row items-center">
      <div class="text-subtitle1">
        <div class="text-capitalize text-caption">{{ $t(types[place.type] || place.type) }}</div>

        <b>{{ name }}</b>
      </div>

      <q-space/>

      <div v-if="typesWithChildren.includes((place || {}).type)">
        <q-icon
            name="navigate_next"
            size="22px"
            color="grey"
        />
      </div>
    </q-card-section>

    <q-card-section v-if="!offline && usedBy.length > 0" class="text-grey-8 text-caption q-px-sm q-py-none">
      <q-icon name="how_to_reg" size="20px"/>

      <span>
        {{ $t('Used by') + ': ' }}

        <span v-if="usedBy.length === 1">
          {{ usedBy[0].email }}
        </span>

        <div v-else>
          <div
              v-for="user in usedBy"
              :key="user.id"
          >
            {{ '- ' + user.email }}
          </div>
        </div>
      </span>
    </q-card-section>

    <div class="place-card__actions">
      <div class="relative fit row items-center justify-center pointer">
        <div class="text-caption text-white" @click="handleSelect">
          <b v-if="typesWithChildren.includes((place || {}).type)">
            {{ $t('Select') + ': ' + place.id }}
          </b>

          <b v-else>
            {{ (place.placeAlias || $t('No name')) + ': ' + place.id }}
          </b>
        </div>

        <p class="absolute-top-right q-ma-sm">
          <q-icon
              color="positive"
              size="1.2rem"
              class="clickable"
              name="print"
              @click="handlePrintOne"
          />

          <q-icon
              color="positive"
              size="1.2rem"
              class="clickable"
              name="offline_share"
              @click="handlePrintMany"
          />

          <q-icon
              color="positive"
              size="1.2rem"
              class="clickable"
              name="edit"
              @click="handleEdit"
          />
        </p>
      </div>
    </div>
  </q-card>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'WarehousePlaceCard',
  emits: ['edit', 'select', 'print'],
  props: {
    level: {
      type: Number,
      default () {
        return 10
      }
    },
    offline: {
      type: Boolean,
      default () {
        return false
      }
    },
    warehouse: {
      type: Object,
      default () {
        return null
      }
    },
    selected: {
      type: Boolean,
      default () {
        return false
      }
    },
    place: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      typesWithChildren: ['room', 'section'],
      types: {
        employee: 'basket'
      }
    }
  },
  computed: {
    ...mapGetters([
      'storagePlaceEvents'
    ]),
    name () {
      if (!this.place.placeAlias) {
        return this.$t('No name')
      }

      return this.place.placeAlias
    },
    usedBy () {
      if (this.offline) {
        return []
      }

      return this.storagePlaceEvents.reduce((acc, event) => {
        if (event.data && event.data.place && event.data.place.id === this.place.id) {
          acc.push({ id: event.data.user, email: event.data.email })
        }

        return acc
      }, [])
    },
    className () {
      if (this.usedBy.length > 0) {
        return 'fade-animation bg-orange-12'
      }

      if (this.selected) {
        return `bg-teal-${this.level}`
      }

      return ''
    }
  },
  methods: {
    handleEdit () {
      this.$emit('edit', this.place)
    },
    handlePrintOne () {
      this.$emit('print', {
        place: this.place
      })
    },
    handlePrintMany () {
      this.$emit('print', {
        place: this.place,
        children: true
      })
    },
    handleSelect () {
      if (!this.typesWithChildren.includes((this.place || {}).type)) {
        return
      }

      this.$emit('select', this.place)
    }
  }
}
</script>
