<template>
  <div>
    <action-header
        :is-loading="!warehouse"
        :actions-model="headerModel"
        :page="page"
    />

    <div class="q-pa-md">
      <q-card>
        <q-expansion-item
            v-model="isOpen"
            expand-separator
            :label="$t('Warehouse') + ': ' + ((warehouse && warehouse.name) || $t('new'))"
        >
          <warehouse-form
              ref="warehouseForm"
              :is-loading="!warehouse || isLoading"
              :has-parent="hasParent"
              :has-type="hasType"
              @change="handleChange"
          />
        </q-expansion-item>
      </q-card>

      <q-card
          v-if="warehouse && warehouse.id && (warehouse.type === 'fulfillment' || warehouse.type === 'virtual')"
          class="q-mt-sm"
      >
        <q-expansion-item
            v-model="isOpenSender"
            expand-separator
            :label="$t('Sender address')"
        >
          <warehouse-sender-panel
              :id="warehouse.eav && warehouse.eav['warehouse-sender']"
              :owner="warehouse._embedded && warehouse._embedded.owner && warehouse._embedded.owner.id"
          />
        </q-expansion-item>
      </q-card>

      <q-card v-if="warehouse && warehouse.type !== 'virtual'" class="q-my-sm">
        <warehouse-places
            ref="baskets"
            :warehouse="warehouse"
            :title="$t('Baskets')"
            :type="'employee'"
            :overflow="'hidden'"
            is-manual
            @change="handleChange"
        />
      </q-card>

      <q-card v-if="warehouse && warehouse.type !== 'virtual'">
        <warehouse-places
            ref="places"
            :direction="'horizontal'"
            :warehouse="warehouse"
            :type="'room'"
            is-manual
            @change="handleChange"
        >
          <div class="border-bottom q-pa-sm text-subtitle1 text-capitalize">
            {{ $t('Locations') }}
          </div>
        </warehouse-places>
      </q-card>
    </div>

    <storage-place-result-modal ref="storagePlaceModal"/>

    <warehouse-services-modal ref="warehouseService"/>

    <eav-modal ref="eavModal" @submit="handleEAVSubmit"/>

    <sticky-bottom-header
        :is-loading="isSaveLoading"
        :is-active="hasChange"
        @back="handleDiscard"
        @save="save"
    />
  </div>
  <transaction-warehouse-modal ref="transactionModal" :warehouse="warehouse"/>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import WarehouseForm from '../../components/warehouse-form/WarehouseForm.vue'
import WarehousePlaces from '../../components/warehouse-form/WarehousePlaces.vue'
import ActionHeader from './../../components/action-header/ActionHeader'
import EavModal from '../../components/modals/EavModal.vue'
import StoragePlaceResultModal from '../../components/modals/StoragePlaceResultModal.vue'
import WarehouseSenderPanel from '../../components/panels/WarehouseSenderPanel.vue'

// Utils
import { convertEmbedded, difference } from '../../helpers/request-helpers'
import TransactionWarehouseModal from '../../components/modals/TransactionWarehouseModal.vue'
import { buildQuery } from '@/apps/app/utils/query-utils'
import WarehouseServicesModal from '@/apps/app/components/modals/WarehouseServicesModal.vue'

/**
 * Create Date
 *
 * @param {object} date
 *
 * @returns {String}
 */
function createDate (date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
}

export default {
  name: 'Warehouse',
  components: {
    WarehouseServicesModal,
    TransactionWarehouseModal,
    ActionHeader,
    WarehouseForm,
    WarehousePlaces,
    EavModal,
    StoragePlaceResultModal,
    WarehouseSenderPanel
  },
  data () {
    return {
      isOpenSender: false,
      isOpen: false,
      isLoading: false,
      isSaveLoading: false,
      hasChange: false,
      hasType: false,
      hasParent: false,
      keysForDelete: ['parent', 'warehouse', 'count', 'children', 'originalChildren']
    }
  },
  computed: {
    ...mapGetters([
      'account',
      'warehouse',
      'isClient',
      'isSupervisior',
      'isAdministrator'
    ]),
    page () {
      return {
        id: this.warehouse && this.warehouse.id,
        name: this.warehouse && this.warehouse.id
            ? this.$t('Warehouse')
            : this.$t('New warehouse')
      }
    },
    headerModel () {
      if (!this.warehouse) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'col-sm-1 q-pr-md hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col-sm-3 mobile-title text-white',
          options: [
            {
              id: 'title',
              type: 'text',
              value: this.warehouse.id ? this.$t('Warehouse ID: ') + this.warehouse.id : this.$t('New Warehouse'),
              additionalValue: this.warehouse && `${this.$t('Type')}: ${this.$t(this.warehouse.type)}`
            }
          ]
        },
        {
          section: 'Actions',
          className: 'col-sm-4 text-center row justify-center',
          options: [
            {
              id: 'transactions',
              wrapperClassName: 'q-px-xs',
              type: 'button',
              label: this.$t('Add transactions'),
              icon: 'add',
              hasIcon: true,
              variant: 'light',
              onClick: () => {
                let data = {
                  entityType: 'Orderadmin\\Storage\\Entity\\Warehouse',
                  entity: this.warehouse.id,
                  state: 'confirmed',
                  transactionDate: createDate(new Date())
                }
                const query = buildQuery({})

                query.filter = [
                  { type: 'eq', field: 'type', value: 'deposit' }
                ]

                if (this.warehouse && this.warehouse._embedded && this.warehouse._embedded.owner) {
                  query.filter.push({ type: 'eq', field: 'owner', value: this.warehouse._embedded.owner.id })
                } else {
                  return
                }

                return this.loadAccounts(query)
                    .then(({ items }) => {
                      const account = items[0]
                      data.account = account.id
                      this.$refs.transactionModal.open(data)
                      return { items }
                    })

              }
            },
            {
              id: 'eav',
              wrapperClassName: 'q-px-xs',
              type: 'button',
              label: this.$t('EAV'),
              hasIcon: true,
              variant: 'light',
              onClick: () => {
                this.$refs.eavModal.open(this.warehouse.eav, { entityClass: this.$entities.Orderadmin_Storage_Entity_Warehouse })
              }
            },
            {
              id: 'services',
              wrapperClassName: 'q-px-xs',
              wrapperStyle: 'max-width: 120px;',
              type: 'button',
              variant: 'light',
              disabled: !this.warehouse || !this.warehouse.id,
              label: this.$t('Services'),
              onClick: () => {
                const defaultData = {
                  state: 'active',
                  warehouse: this.warehouse.id,
                }

                this.$refs.warehouseService.open(this.warehouse.id, defaultData)
              }
            },
          ]
        }
      ]
    }
  },
  mounted () {
    if (!this.$route.params.id) {
      this.isOpen = true
      this.setNewWarehouse()

      this.hasType = !!this.warehouse.type

      if (!this.isSupervisior && !this.isAdministrator) {
        const owner = JSON.parse(localStorage.getItem('userData'))
        this.updateWarehouseEmbedded({ owner })
      }

      if (this.warehouse.type === 'virtual') {
        const query = {
          per_page: 5,
          page: 1,
          fields: ['extended'],
          filter: [
            { type: 'eq', field: 'type', value: 'fulfillment' },
            { type: 'eq', field: 'state', value: 'active' }
          ]
        }

        this.isLoading = true
        return this.$service.warehouse.getAll(query)
            .then(({ items, totalItems }) => {
              if (totalItems === 1) {
                const parent = items[0]

                if (!parent._embedded) {
                  parent._embedded = {}
                }

                this.updateWarehouseEmbedded({
                  parent,
                  country: parent._embedded.country || this.getValue(parent.country),
                  currency: parent._embedded.currency || this.getValue(parent.currency),
                  length: parent._embedded.length || this.getValue(parent.length),
                  weight: parent._embedded.weight || this.getValue(parent.weight)
                })

                this.hasParent = true
              }
            })
            .finally(() => {
              this.isLoading = false
            })
      }

      return
    }

    return Promise.resolve(this.$route.params.id && !this.warehouse)
        .then(shouldLoad => {
          return shouldLoad
              ? this.loadWarehouse(this.$route.params.id)
              : this.warehouse
        })
        .then(warehouse => {
          this.hasType = true

          if (warehouse.type === 'virtual') {
            return
          }

          return this.$refs.baskets.reset()
              .then(() => {
                return this.$refs.places.reset()
              })
        })
  },
  unmounted () {
    this.setWarehouse(null)
  },
  methods: {
    ...mapActions([
      'loadWarehouse',
      'saveWarehouse',
      'loadAccounts'
    ]),
    ...mapMutations([
      'setWarehouse',
      'setNewWarehouse',
      'upsertWarehouse',
      'addErrorNotification',
      'updateWarehouseEmbedded',
      'updateWarehouse'
    ]),
    handleEAVSubmit (eav) {
      this.updateWarehouse({ eav })
      this.hasChange = true
    },
    getValue (val) {
      return typeof val === 'string'
          ? JSON.parse(val)
          : val
    },
    handleChange () {
      this.hasChange = true
    },
    refresh () {
      this.loadWarehouse(this.$route.params.id)
    },
    save () {
      if (this.warehouse && !this.warehouse.id && this.warehouse.type === 'virtual') {
        const parent = this.warehouse._embedded.parent

        if (!parent) {
          this.addErrorNotification('Fulfillment facility is required!')
          this.$refs.warehouseForm.setFieldsWithErrors(['parent'])
          this.isOpen = true
          return
        }
      }

      const fields = this.$refs.warehouseForm.getFieldsWithoutValue()

      if (fields.length > 0) {
        fields.forEach(field => {
          this.addErrorNotification(`Field ${field.label} is required!`)
        })

        this.$refs.warehouseForm.setFieldsWithErrors(fields.map(x => x.field))
        this.isOpen = true

        return
      }

      this.isSaveLoading = true
      return this.saveWarehouse()
          .then(warehouse => {
            this.upsertWarehouse(warehouse)

            if (this.warehouse.type !== 'virtual') {
              return this.saveBaskets(warehouse)
                  .then((results) => {
                    return Promise.all([results, this.saveLocations(warehouse)])
                  })
                  .then(([baskets, locations]) => {
                    if (this.$refs.baskets) {
                      this.$refs.baskets.reset()
                          .then(() => {
                            return this.$refs.places.reset()
                          })
                          .then(() => {
                            this.hasChange = false
                          })
                    }

                    const places = [...baskets, ...locations]

                    if (places.length <= 0) {
                      return warehouse
                    }

                    return this.$refs.storagePlaceModal.show(places)
                        .then(() => {
                          return warehouse
                        })
                  })
            }

            if (this.isClient && !this.warehouse.id) {
              return this.saveAutomaticEntities(Object.entries(this.getAutomaticCreatedEntities()))
                  .then(() => {
                    return warehouse
                  })
            }

            return warehouse
          })
          .then(() => {
            if (!this.warehouse.id) {
              this.handleBack()
            }
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },
    // Get entities which we create automatic. We shouldn't create this entities from wizard
    getAutomaticCreatedEntities () {
      const entitiesByDomain = window.appOptions.automaticCreatedEntities || {}
      const entities = entitiesByDomain.all || entitiesByDomain[window.appOptions.domain] || {}

      return Object.keys(entities)
    },
    getOwner () {
      if (this.warehouse._embedded.owner) {
        return this.warehouse._embedded.owner.id
      }

      return undefined
    },
    saveAutomaticEntities (entities, results) {
      const saveFn = {
        sender: (defaultState) => {
          const data = {
            ...defaultState,
            name: this.warehouse.name,
            parent: this.warehouse._embedded.parent.eav['warehouse-sender'],
            owner: this.getOwner()
          }

          return this.$service.sender.save(data)
        },
        shop: (defaultState) => {
          const data = {
            ...defaultState,
            name: this.warehouse.name,
            owner: this.getOwner()
          }

          return this.$service.shop.save(data)
        }
      }

      if (entities.length <= 0) {
        return Promise.resolve(results)
      }

      const [key, defaultState] = entities[0]

      if (!saveFn[key]) {
        return this.saveAutomaticEntities(entities.slice(1), results)
      }

      return saveFn[key](defaultState)
          .then(result => {
            results.push(result)
            return this.saveAutomaticEntities(entities.slice(1), results)
          })
    },
    saveBaskets (warehouse) {
      const places = this.$refs.baskets.getPlaces()
      const originalPlaces = this.$refs.baskets.getOriginalPlaces()
      return this.savePlaces(warehouse, null, places, originalPlaces, [])
    },
    saveLocations (warehouse) {
      const places = this.$refs.places.getPlaces()
      const originalPlaces = this.$refs.places.getOriginalPlaces()
      return this.savePlaces(warehouse, null, places, originalPlaces, [])
    },
    savePlaces (warehouse, parent, places, orPlaces, results = []) {
      if (this.warehouse.type === 'virtual') {
        return Promise.resolve([])
      }

      var placesArray
      if (typeof places === 'object') {
        placesArray = Object.keys(places).map(function (key) {
          return places[key]
        })
      } else {
        placesArray = places
      }

      if (placesArray.length <= 0) {
        return Promise.resolve(results)
      }

      const place = placesArray[0]
      const orPlace = orPlaces.find(x => x.id === place.id)

      return Promise.resolve(!!orPlace)
          .then(isOldPlace => {
            return isOldPlace
                ? this.saveOldPlace(place, orPlace)
                : this.createPlace(place, warehouse, parent)
          })
          .then(updatedPlace => {
            if (!updatedPlace) {
              return []
            }

            place.id = updatedPlace.id

            if (Object.keys(updatedPlace).filter(x => x !== 'id').length > 0) {
              results.push(updatedPlace)
            }

            if (place.children) {
              return this.savePlaces(warehouse, place, place.children, orPlaces)
            }

            return []
          })
          .then(childrenResults => {
            results = [...results, ...childrenResults]

            return this.savePlaces(warehouse, parent, places.slice(1), orPlaces, results)
          })
    },
    saveOldPlace (place, orPlace) {
      const data = convertEmbedded(difference(place, orPlace))

      this.keysForDelete.forEach(key => {
        delete data[key]
      })

      if (Object.keys(data).length <= 0) {
        return Promise.resolve({ id: place.id })
      }

      return this.$service.storagePlace.save(data, place.id)
    },
    createPlace (place, warehouse, parent) {
      if (place.state === 'inactive') {
        return Promise.resolve(null)
      }

      const newPlace = {
        ...place,
        children: undefined,
        originalChildren: undefined,
        count: undefined,
        id: undefined,
        warehouse: warehouse.id
      }

      if (parent) {
        newPlace.parent = parent.id
      }

      return this.$service.storagePlace.save(newPlace)
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    }
  }
}
</script>
